// 关于我们

<template>
  <div class="about">
    关于我们
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.about {
min-height: 500px;
}
</style>
